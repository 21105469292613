<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
            <b-row>
                <b-col>
                    <h4>Timesheet Group breakdown</h4>
                </b-col>
            </b-row>
            <b-row>
                <!-- <b-col cols="4">
                    <label>Technical Group</label>
                    <b-input-group>
                        <b-form-input v-model="search.technicianGroup" :disabled="true"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm"  text="Button" variant="secondary" @click="openTechnicianGroupModal">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col> -->
                <b-col cols="4" v-if="search.techGroupId === null">
                    <label>Technician</label>
                    <b-input-group>
                        <b-form-input v-model="search.tech.name" :disabled="true"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm"  text="Button" variant="secondary" @click="openAddSupervisorModal">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="4" v-if="search.techGroupId !== null">
                    <label>Technician</label>
                    <b-form-select v-model="search.tech.userId">
                        <b-form-select-option v-for="(item, index) in technicians" :key="index" :value="item.userId">{{item.name}}</b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="4">
                    <label>Date From</label>
                    <b-form-datepicker v-model="search.dateFrom"></b-form-datepicker>
                </b-col>
                <b-col cols="4">
                    <label>Date To</label>
                    <b-form-datepicker v-model="search.dateTo" :disabled="search.dateFrom === null" 
                      :min="search.dateFrom" :max="maxDateForTo()"></b-form-datepicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>Ineffiecency Hours</label>
                    <b-form-input v-model="search.ineffeciencyHours" type="number"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Lunch Hours</label>
                    <b-form-input v-model="search.lunchHour" type="number"></b-form-input>
                </b-col>
            </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col class="text-right">
              <b-button variant="red" squared class="mr-2" @click="clearFields()">Clear</b-button>
              <b-button variant="primary" squared class="text-right" @click="doExportDocument()">Export</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="fetchingData === true">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-center">
              <h6 class="font-weight-bold"> Fetching data. This might take a while</h6>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    
        <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="user.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="user.surname"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Email</label>
                    <b-form-input v-model="user.email"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Cancel</b-button>
                    <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="tableDataUsers.dataSource" 
                            :fields="tableDataUsers.tableColumns"
                            :busy="tableDataUsers.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="tableDataUsers.resultsPerPage"
                            id="userSearch"
                            :current-page="tableDataUsers.currentPage" sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableDataUsers.currentPage"
                    :total-rows="userRows"
                    :per-page="tableDataUsers.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>

        
        <b-modal id="search-technician-modal" size="lg" hide-footer no-header @close="closeTechnicianSearchModal">
            <b-row>
                <b-col cols="8">
                    <label>Group Name</label>
                    <b-form-input v-model="technicianSearchField.name"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearTechnicianGroupSearchField">Clear</b-button>
                    <b-button variant="primary" squared @click="searchTechnicianGroups">Search</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-table striped hover 
                         :items="technicianDataTable.dataSource" 
                         :fields="technicianDataTable.tableColumns"
                         :busy="technicianDataTable.isLoading" 
                         @row-clicked="addTechnicianGroup"
                         :per-page="technicianDataTable.resultsPerPage"
                         id="technicianModalTable"
                         :current-page="technicianDataTable.currentPage" sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                        <b-button @click="addTechnicianGroup(row.item)" size="sm" class="btn-icon">
                            <b-icon-chevron-right></b-icon-chevron-right>
                        </b-button>
                        </b-row>
                    </template>
                </b-table>
            </b-row>
            <b-row align-h="center" >
                <b-pagination
                    v-model="technicianDataTable.currentPage"
                    :total-rows="technicianDataTableRows"
                    :per-page="technicianDataTable.resultsPerPage"
                    aria-controls="technicianModalTable"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeTechnicianSearchModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>

  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  data:() => ({
    selectedParty: 'Supervisor',
    reportOptions: ['Supervisor', 'Company'],
    search: {
      techGroupId: null,
      technicianGroup: null,
      tech: {name: null, userId:null},
      dateFrom: null,
      dateTo: null,
      ineffeciencyHours: 0,
      lunchHour: 0
    },
    jsonData: [],
    technicians: [],
    technicianDataTable: {
      resultsPerPage: 5,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Group Name',
          key: 'groupName',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    technicianSearchField: {
      name: null,
    },
    fetchingData: false,
        user: {
            name: null,
            surname: null,
            email: null,
            roleID: 0
        },
        tableDataUsers: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                label: 'Name',
                key: 'name',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Surname',
                key: 'surname',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Email',
                key: 'email',
                sortable: true,
                tdClass: '',
                },
                {
                label: '',
                key: 'actions',
                sortable: false,
                tdClass: ''
                }
            ]
        },
  }),
  created(){
    this.setBreadcrumb([
        {
            text: 'Reports'
        },
        {
            text: 'Timesheet Group Breakdown',
            active: true
        }
    ])
  },
  methods:{
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions(['downloadComplianceReport', 'searchUsersRequest', 'downloadEffiecencyReport', 'searchTechnicianGroup', 'searchTechniciansForTimesheet']),
    ...mapActions('notificationService', ['addWarningMessage']),
    clearFields(){
      //console.log(this.search)
      this.search = {
        techGroupId: null,
        technicianGroup: null,
        tech: {name: null, userId:null},
        dateFrom: null,
        dateTo: null,
        ineffeciencyHours: 0,
        lunchHour: 0
      }
    },
    doExportDocument(){
      this.fetchingData = true
      if (this.search.dateFrom === null || this.search.dateTo === null) {
          this.addWarningMessage('Dates are required for this download')
          return
      }
      let request = {
          techGroupId: this.search.techGroupId,
          techId: this.search.tech.userId,
          dateFrom: this.search.dateFrom,
          dateTo: this.search.dateTo,
          ineffiecencyHours: this.search.ineffeciencyHours,
          lunchHours: this.search.lunchHour
      }

      this.$store.commit('setDownloadRequest', request)
      this.downloadEffiecencyReport()
      .then((res) => {
        this.fetchingData = false

        if (res.data !== '') {
          const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
          const downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data.fileName;
          downloadLink.click();
        }
        else{
          this.addWarningMessage('No data available to download')
        }
      })
      .catch(() => {
        this.fetchingData = false
      })
    },

    addTechnicianGroup(rowItem) {
      this.search.techGroupId = rowItem.pkTechnicianGroupId
      this.search.technicianGroup = rowItem.groupName
      this.searchTechnician()
      this.closeTechnicianSearchModal()
    },
    
    searchTechnician() {      
      this.$store.commit('setTechnicianSearchRequest', this.search.techGroupId)
      this.searchTechniciansForTimesheet()
      .then((response) => {
        this.technicians = response.data
        this.state = 'show'
      })
    },

    openTechnicianGroupModal() {
        this.$bvModal.show('search-technician-modal')
        this.searchTechnicianGroups()
    },
    closeTechnicianSearchModal() {
        this.$bvModal.hide('search-technician-modal')
    },
      searchTechnicianGroups(){
        let request = []
        if(this.technicianSearchField.name !== null){
          request.push({"key":"groupName","value": this.technicianSearchField.name})
        }
        this.$store.commit('setTechnicianGroupSearchRequest', request)
        this.searchTechnicianGroup()
            .then((response) => {
              this.state = 'show'
              this.technicianDataTable.dataSource = response.data
              this.technicianDataTable.isLoading = false
            })
      },
      clearTechnicianGroupSearchField() {
        this.technicianSearchField = {
          name: null,
        }
        this.searchTechnicianGroups()
      },
    chooseUser(rowItem){
      this.search.tech = rowItem
      this.closeSearchUserModal()
    },
    changePartySelection(){
      this.search = {
        supervisor: {name: null},
        description: null,
        dateCreatedFrom: null,
        dateCreatedTo: null
      }
    },
    
        openAddSupervisorModal(){
            this.$bvModal.show('search-user-modal')
            this.user.roleId = 1
            this.doSearchUsers()
        },
        closeSearchUserModal(){
            this.$bvModal.hide('search-user-modal')
        },
        clearUsersFilter(){
            this.user = {
                name: null,
                surname: null,
                email: null
            }
        },
        
        doSearchUsers(){
            this.tableDataUsers.isLoading = true
      
            let request = []
            
            if (this.user.name != null) {
                request.push({'key': 'name', 'value': this.user.name})
            }
            if (this.user.surname != null) {
                request.push({'key': 'surname', 'value': this.user.surname})
            }
            if (this.user.email != null) {
                request.push({'key': 'email', 'value': this.user.email})
            }
            if (this.user.roleId != null) {
                request.push({'key': 'fkroleId', 'value': this.user.roleId})
            }
            
            this.$store.commit('setUserSearchRequest', request)
            
            this.searchUsersRequest()
            .then((request) => {
                this.tableDataUsers.dataSource = request.data
                
                this.jsonData = this.tableDataUsers.dataSource.map(v => ({
                name: v.name,
                surname: v.surname,
                contactNumber: v.contactNumber,
                email: v.email,
                role: v.role,
                lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
                }))
                
                this.tableDataUsers.isLoading = false
                this.state  = 'initialize'
            })
            .catch( () => {
                this.tableDataUsers.isLoading = false
            })
        },
        maxDateForTo(){
          if (this.search.dateFrom === null) return null
          else {
            let from = new Date(this.search.dateFrom)
            let max = new Date(from.getTime() + 30 * 24 * 60 * 60 * 1000)
            return max
          }
        }
  },
  computed: {
      technicianDataTableRows() {
        return this.technicianDataTable.dataSource.length
      },
        userRows() {
            return this.tableDataUsers.dataSource.length
        },
  }
}
</script>

<style scoped>

</style>